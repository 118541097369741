@import '~@fontsource/inter';
@import '~@calcom/atoms/globals.min.css';
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400..700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* @media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
  body {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */

@layer base {
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/assets/fonts/AvenirNextLTPro-Regular.otf) format('opentype');
  }

  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(/assets/fonts/AvenirNextLTPro-Medium.woff) format('opentype');
  }

  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(/assets/fonts/AvenirNextLTPro-Bold.otf) format('opentype');
  }
}

@layer utilities {
  #in-app-booker .hide-meta {
    display: none;
  }

  #in-app-booker .adjust-booker-container {
    width: auto;
    grid-template-columns: auto;
  }

  .grecaptcha-badge {
    visibility: hidden;
    display: none;
  }

  .input-no-outline {
    appearance: none;
    -webkit-appearance: none;
  }

  .hide-scrollbar {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .hide-time-icon::-webkit-calendar-picker-indicator {
    display: none;
  }

  .onboarding-responsive-title {
    font-size: calc(19 * (clamp(346px, 100vw, calc(100vw - 360px)) / 346));
    line-height: calc(17 * (clamp(346px, 100vw, calc(100vw - 360px)) / 346));
    max-width: 75%;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .safe-bottom {
    padding-bottom: env(safe-area-inset-bottom);
  }

  .safe-top {
    padding-top: env(safe-area-inset-top);
  }

  .remove-shadow {
    box-shadow: none;
    background-image: none;
  }

  .app-shadow {
    box-shadow:
      rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }

  .scrollable-shadow-mobile::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 92px;
    border-radius: 15px;
    width: 370px; /* Adjust the width to your preference */
    pointer-events: none;
    background-image: linear-gradient(
      to right,
      transparent 0%,
      rgba(240, 240, 240) 60%,
      rgb(240, 240, 240) 90%
    );
  }
  .scrollable-shadow-black-mobile::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 200px; /* Adjust the width to your preference */
    pointer-events: none;
    background-image: linear-gradient(
      to right,
      transparent 0%,
      rgba(0, 0, 0) 50%,
      rgb(0, 0, 0) 100%
    );
  }
}

.grecaptcha-badge {
  visibility: hidden;
  display: none;
}

.AvailabilityViewer {
  .AvailabilityViewer__calendars {
    /* @apply grid grid-cols-2 gap-4; */
  }
  .AvailabilityViewer__submit {
    @apply rounded-full;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* modal styling */
@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.animate-slideDown {
  animation: slideDown 0.3s ease-out;
}

.close-button {
  position: relative;
  bottom: 10px;
  right: 10px;
}

/** Modal **/
.modal-container {
  background-color: rgb(0 0 0 / 80%);
}

.surrounding-button:nth-child(1) {
  top: 0;
  left: -108%;
  transform: translateX(-108%) rotate(0deg);
}

.surrounding-button:nth-child(2) {
  top: -100%;
  left: -55%;
  transform: translateY(-100%) rotate(0deg);
}

.surrounding-button:nth-child(3) {
  top: -100%;
  right: -55%;
  transform: translateY(-100%) rotate(0deg);
}

.surrounding-button:nth-child(4) {
  top: 0;
  right: -108%;
  transform: translateX(108%) rotate(0deg);
}

.surrounding-button:nth-child(5) {
  bottom: -100%;
  right: -55%;
  transform: translateY(-100%) rotate(0deg);
}

.surrounding-button:nth-child(6) {
  top: 0;
  right: 0;
}

.scaled-booker-wrapper {
  /* Scale transform on the wrapper */
  transform: scale(var(--scale-factor));
  transform-origin: top left;

  /* Adjust the height and width to compensate for scaling */
  /* height: calc(100% / var(--scale-factor)); */
  width: calc(100% / var(--scale-factor));
}

.scaled-booker-wrapper * {
  /* Adjust sizes for all child elements */
  font-size: inherit;
}

.scaled-booker-wrapper input,
.scaled-booker-wrapper select,
.scaled-booker-wrapper button {
  /* Adjust specific form elements if needed */
  font-size: calc(1em / var(--scale-factor));
  padding: calc(0.5em / var(--scale-factor));
}

.calcom-atoms {
  width: 100%;
}
.scaled-booker-wrapper [class*='grid-area:main'] {
  border: 0 !important;
}

.calcom-atoms [class*='grid-area:meta'] {
  width: 100% !important;
}

.scaled-booker-wrapper [data-testid='booker-container'] {
  border: 0 !important;
}

.editableAvailabilityContainerClassName .border-subtle {
  border: 0;
}

.editableAvailabilityContainerClassName .p-4 {
  padding: 0;
}

.editableAvailabilityContainerClassName .dayRanges button,
.editableAvailabilityContainerClassName > div:first-child label,
.editableAvailabilityContainerClassName > div:first-child > div:last-child button:last-child,
.editableAvailabilityContainerClassName .block:not(span) {
  /* display: none !important; */
}

.editableAvailabilityContainerClassName .timeRanges > div {
  width: max-content;
  font-family: 'Avenir';
}

.editableAvailabilityContainerClassName > div:first-child {
  margin-bottom: 0;
}

.editableAvailabilityContainerClassName .timeRanges .h-9 {
  background: #000;
  color: #fff;
  border-radius: 20px;
  width: 85px;
  padding: 8px;
  width: 80px;
}

.editableAvailabilityContainerClassName .scheduleDay {
  margin-bottom: 6px;
}

.editableAvailabilityContainerClassName .timeRanges .h-9 > div {
  display: flex;
  justify-content: center;
}

.editableAvailabilityContainerClassName .timeRanges .h-9 > div .text-emphasis:first-child {
  position: absolute;
}

.editableAvailabilityContainerClassName .timeRanges .h-9 * {
  color: #fff;
}

.editableAvailabilityContainerClassName .labelAndSwitchContainer {
  font-family: 'Avenir';
  font-size: 10px;
}

.editableAvailabilityContainerClassName .labelAndSwitchContainer button {
  border: 0 !important;
  box-shadow: none !important;
  transition: 0.3s all ease;
}

.editableAvailabilityContainerClassName .labelAndSwitchContainer .block {
  height: 18px;
  width: 18px;
  margin-left: 1px;
  transition: 0.3s all ease;
}

.editableAvailabilityContainerClassName .labelAndSwitchContainer button[data-state='unchecked'] {
  background-color: #db1b24cc;
  transition: 0.3s all;
}

.editableAvailabilityContainerClassName .labelAndSwitchContainer .block[data-state='checked'] {
  margin-left: 15px;
}
